<template>
  <mobile-screen
    :header="true"
    screen-class="icon-app1 one-click-screen gray-bg"
  >
    <template v-slot:header>
      <top-header-menu-wrapper
        menu-class="one-click-header icon-hea1"
        :helpOnline="
          Boolean(
            helpOnline[helpSlug] &&
              helpOnline[helpSlug].name &&
              helpOnline[helpSlug].content
          )
        "
        :helpOnlineRoute="{
          name: 'r_one-click-settings-help-online'
        }"
      >
        <template v-slot:left>
          <router-link :to="{ name: 'r_one-click' }">
            <icon icon="#cx-hea1-arrow-left" />
          </router-link>
        </template>
        <div class="component-title">
          {{ displayLabelName("one-click", "settings", "settings") }}
        </div>
      </top-header-menu-wrapper>
    </template>
    <ul class="clebex-item-section pill" v-if="widgets && widgets.length">
      <li
        class="clebex-item-section-item slide-icon-border full-right-underline"
        v-for="widget in widgets"
        :key="widget.widget_id"
      >
        <div class="clebex-item-content-wrapper">
          <dl class="clebex-item-dl justify-start">
            <dt class="clebex-item-dt">
              <div class="checkbox slide">
                <input
                  :id="`oneClickSettings${widget.widget_id}`"
                  type="checkbox"
                  v-model="widget.is_active"
                  @change="setActive"
                />
                <label :for="`oneClickSettings${widget.widget_id}`"></label>
              </div>
            </dt>
            <dd class="clebex-item-dd justify-start">
              <button
                @click="widgetSettingsPage(widget.widget_id)"
                :class="{
                  'cursor-default': !(widget.widget_id in widgetSettings)
                }"
                class="w-100"
              >
                <div>
                  {{ widget.name }}
                </div>
                <icon
                  v-if="
                    widget.is_active &&
                      widget.widget_id != 2 &&
                      widget.widget_id != 6
                  "
                  icon="#cx-app1-arrow-right-12x12"
                  width="12"
                  height="12"
                />
              </button>
            </dd>
          </dl>
        </div>
      </li>
    </ul>
    <template v-slot:footer>
      <nav class="actions-menu theme-gray"></nav>
    </template>
  </mobile-screen>
  <router-view />
</template>

<script>
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import { mapState, mapActions } from "vuex";
import helpOnlineMixin from "@/services/mixins/help_online/help-online-mixin";

export default {
  name: "OneClickSettings",
  mixins: [helpOnlineMixin],
  components: {
    MobileScreen,
    TopHeaderMenuWrapper
  },
  data() {
    return {
      widgetSettings: {
        1: "r_one-click-plan",
        3: "r_one-click-contacts",
        4: "r_one-click-info",
        5: "r_one-click-map"
      },
      helpSlug: "one-click-settings"
    };
  },
  created() {
    this.getWidgets();
  },
  computed: {
    ...mapState("oneClick", ["widgets"])
  },
  methods: {
    ...mapActions("oneClick", ["setWidgetUsers", "getWidgets"]),
    setActive() {
      const postArray = [];
      this.widgets.forEach(element => {
        postArray.push({
          id: element.id,
          widget_id: element.widget_id,
          is_active: element.is_active ? 1 : 0,
          ordering: element.ordering,
          type: element.type
        });
      });
      this.setWidgetUsers(postArray);
    },
    widgetSettingsPage(id) {
      if (!this.widgets.find(el => el.widget_id === id).is_active) {
        return;
      }
      this.$router.push({ name: this.widgetSettings[id] });
    }
  }
};
</script>
